import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <rect width="20" height="20" fill="transparent"/>
      <path d="M20 0H0V20H20V0Z" fill="white" fillOpacity="0.01"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2 16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V4C18 2.89543 17.1046 2 16 2H13.5556C13.0171 2 12.5494 2.33561 12.2581 2.7885C11.6933 3.66663 10.9406 4.10569 10 4.10569C9.05941 4.10569 8.30671 3.66663 7.74189 2.7885C7.45059 2.33561 6.98293 2 6.44444 2H4C2.89543 2 2 2.89543 2 4V16Z" fill="#F5C22A"/>
      <path d="M6.25 10L8.75 12.5L13.75 7.5" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
